<template>
    <div class="main">
         <TheTopbar/>
       
		
         <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
           
        <div class="main-container">
          <h6 class="text-dark">ADD ENTITY TO WATCHLIST</h6>
          <span><p v-if="showError">{{errorMsg}}</p></span>
          
          <form class="form-wrapper" v-on:submit.prevent="createCustomer">
         
             
             <div class="container form-group d-flex justify-content-evenly">
                <div class="form-group d-flex justify-content-evenly">
                  
                    <div class="d-block">
                      <label class="label" for="firstName">First Name: <strong>*</strong></label>
                    <input type="text" class="form-control" id="firstName" v-model="profile.firstName">
                    </div>
                    <div class="d-block">
                        <label class="label" for="lastName">Last Name: <strong>*</strong></label>
                      <input type="text" class="form-control" id="lastName" placeholder="" v-model="profile.lastName">
                    </div>
                    <div class="d-block">
                      <label for="exampleFormControlSelect1">Nationality:</label>
                        <select class="form-control" id="exampleFormControlSelect1" v-model="profile.title" @change="getCountryCode">
                            <option v-for="code in list.mobileCodes" :key="code.id">{{ code.country }}</option>
                          </select>
                    </div>
                </div>
              
             </div>
             <div class="container form-group d-flex justify-content-evenly">
                
              <div class="form-group d-flex justify-content-evenly">
             <div class="d-block">
               <label for="address"> Day: <strong>*</strong></label>
               <b-form-select class=" form-control" v-model="profile.birth.day" :options="days"></b-form-select>
             </div>
             <div class="d-block">
               <label for="address"> Month: <strong>*</strong></label>
               <b-form-select class=" form-control" v-model="profile.birth.month" :options="months"></b-form-select>
             </div>
             <div class="d-block">
               <label for="address"> Year: <strong>*</strong></label>
                 <b-form-select class=" form-control" v-model="profile.birth.year" :options="years"></b-form-select>
               
             </div>

           </div>
              
            </div>
              <div class="container form-group d-flex justify-content-evenly">
                <div class="form-group d-flex justify-content-evenly">
                    <div class="d-block">
                        <label class="label" for="firstName">Email Address: <strong>*</strong></label>
                      <input type="text" class="form-control" id="firstName" v-model="profile.emailAddress">
                        
                      </div>
                      <div class="d-block">
                         <div class="d-block">
                        
                        <div class="row">
                          <div class="form-group col">
                            <label for="inputMobile">Mobile Number: <strong>*</strong></label>
                            <div class="flex-wrap mobileWrapper">
                              <div class="input-group-prepend code"  v-on:click="toggleCountryList" >
                                <button class="flag-btn " type="button" >
                                  <img class="flag-list-img my-auto" :src="require('../assets/signup_flags/' + country + '.png' )">
                                </button>
                                <h6 class="mt-2 text-dark">+{{ areaCode }}</h6>
                              </div>
                              <div class="country-list" v-if="showCountry">
                                <div class="row">
                                <div class="col-sm-6" v-for="code in list.mobileCodes" v-on:click="selectCountry(code)" :key="code.id" >
                                  <div >
                                    <img class="flag-list-img" :src="require('../assets/signup_flags/' + code.countryCode + '.png' )">
                                  </div>
                                  <div class="country-name"> <span> {{ code.country }} </span> </div>
                                </div>
                                </div>
                              </div>
                              
                            <div class="col">
                              <input type="text" v-model="profile.mobilePhone" @keypress="isNumber($event)"  class="form-control">
                            </div>
                          </div>
                          
                                    
                          </div>
                        </div>
                    </div>
                    </div>
                    <!-- <div class="d-block">
                          <label class="label" for="lastName">Home Phone Number: <strong>*</strong></label>
                        <input type="text" class="form-control" id="lastName" placeholder="" v-model="profile.homePhone">
                    </div> -->
                    
                </div>
             </div>
            
           
          
                <div class=" form-group justify-content-center">
                    <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                </div>

          </form>
              <div class=" form-group justify-content-center">
                    <button class="btn btn-upload" id="saveBtn" @click="upload()">Upload in Bulk</button>
					          
                </div>

        </div>
          

           <UploadBulk  v-if="showUpload" @close="upload()"></UploadBulk>
    </div>
    
</template>

<script>

import TheTopbar from '../components/TheTopbar.vue'
import UploadBulk from '../components/modal/UploadBulk.vue'

export default {
  
    name: 'AddToWatchlist',
    components:{
      TheTopbar,
      UploadBulk


        
    },
    data: function(){
        return{
          brandName:'',
          isMobile: false,
          showUpload: false,
          showCountry: false,
          country: 'PH',
          countryName: 'Philippines',
          areaCode: '63',
          list: {
		          mobileCodes: []
		        },
          errorMsg:"",
          showError: false,
           profile: {
                  middleName:'',
                  title:"",
                  firstName: "",
                  lastName: "",
                  address: "-",
                  numberStreet:"-",
                  city: "",
                  mobilePhone: "",
                  emailAddress:"",
                  country:"PH",
                  state:"",
                  zipcode:"-",
                  signupHost:"watchlist.ceza.org",
                  homePhone:"-",
                  birth:{
                    day:null,
                    month:null,
                    year:null
                  },
            },
            host: [
              {text: 'fintech operator', value: 'fintech.ceza.org'}, 
              {text:'gaming operator',value: 'gaming.ceza.org'},
            ],
            title: [
              {text: 'Mr', value: 'Mr'}, 
              {text: 'Mrs', value: 'Mrs'}, 
              {text: 'Ms', value: 'Ms'}, 
            ],
            months: [
                    {text: 'January', value: '01'}, 
                    {text:'February',value: '02'},
                    {text:'March',value: '03' },
                    {text:'April',value: '04' },
                    {text:'May', value: '05'},
                    {text:'June', value: '06'},
                    {text:'July', value: '07'},
                    {text:'August',value: '08'},
                    {text:'September', value: '09'},
                    {text:'October', value: '10'},
                    {text:'November', value: '11'},
                    {text:'December', value: '12'}
                    ],
              days: [
                    {text: '1', value: '01'}, 
                    {text:'2',value: '02'},
                    {text: '3', value: '03'}, 
                    {text:'4',value: '04'},
                    {text: '5', value: '05'}, 
                    {text:'6',value: '06'},
                    {text: '7', value: '07'}, 
                    {text:'8',value: '08'},
                    {text: '9', value: '09'}, 
                    {text:'10',value: '10'},
                    {text: '11', value: '11'}, 
                    {text:'12',value: '12'},
                    {text: '13', value: '13'}, 
                    {text:'14',value: '14'},
                    {text: '15', value: '15'}, 
                    {text:'16',value: '16'},
                    {text: '17', value: '17'}, 
                    {text:'18',value: '18'},
                    {text: '19', value: '19'}, 
                    {text:'20',value: '20'},
                    {text: '21', value: '21'}, 
                    {text:'22',value: '22'},
                    {text: '23', value: '23'}, 
                    {text:'24',value: '24'},
                    {text:'25',value: '25'},
                    {text: '26', value: '26'}, 
                    {text:'27',value: '27'},
                    {text: '28', value: '28'}, 
                    {text:'29',value: '29'},
                    {text: '30', value: '30'}, 
                    {text:'31',value: '31'},
                    ],
        }
    },
    computed:{
        init() {
          return this.$store.state.init;
        },
        errors() {
          return this.$store.state.errors;
        },
         years () {
				const year = new Date().getFullYear()
				return Array.from({length: year - 1900}, (value, index) => 1901 + index)
				}

    },
    methods:{
      changeFlag() {
				this.axios
					.get("/assets/js/mobilecodes.json")
					.then((response) => {
						this.list.mobileCodes = response.data;
						
						let mobileCodes = [];
						this.list.mobileCodes.forEach((element, index) => {
							mobileCodes.push(element)
							
						if(element.mobileCode == this.areaCode) {
								this.selectedMobile = element.mobileCode
							}
							
						}) 
					})
					.catch((error) => {
						// console.log(error);
					});
			},
      goBack(){
        this.$router.go(-1);
        },
			selectCountry: function(code) {
				this.showCountry = false;
				this.country = code.countryCode;
				this.areaCode = code.mobileCode;
			},
			toggleCountryList: function(){
        this.showCountry = !this.showCountry;
			
        	},
			getMobileCodes: function() {
		        this.axios.get('/assets/js/mobilecodes.json')
		          .then((response) => {  
		              this.list.mobileCodes = response.data; 
		          }).catch((err) => {
		              console.log(err);

		          })
		      },
			
			getCountryCode() {
				this.list.mobileCodes.forEach((value, index) => {
                if(this.countryName == value.country) {
                    this.country = value.countryCode
                }
            })
			},
			 isNumber: function(evt) {
		        evt = (evt) ? evt : window.event;
		        var charCode = (evt.which) ? evt.which : evt.keyCode;
		        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
		          evt.preventDefault();
		        } else {
		          return true;
		        }
		      },

      createCustomer(){
      console.log(this.profile.signupHost)
				let params = {
          
					title: this.profile.title,
					firstName: this.profile.firstName,
					lastName: this.profile.lastName,
					emailAddress: this.profile.emailAddress,
					mobilePhone: this.profile.mobilePhone,
					birthDd: this.profile.birth.day,
          birthMm:this.profile.birth.month,
          birthYear:this.profile.birth.year,
					"presentAddress.addressExtraInfo": "",
					"presentAddress.cityTown": this.profile.city,
					"presentAddress.country":this.profile.country,
					"presentAddress.numberStreet": this.profile.numberStreet,
					"presentAddress.postalCode":this.profile.zipcode,
					"presentAddress.provState": this.profile.provState,
					homePhone: this.profile.homePhone,
					signupHost: this.profile.signupHost, 
					enableCryptocurrency:true,
          permanentAddrSameAsPresent: true
				
				}
      
				
				

				const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');



        const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/createCustomer',
        data
				};
    	  this.axios(options)
        .then((response) => {
          window.location.href="/#/customer-search";
      
        }).catch((err) => {
          if(err.response.data.fieldErrors){
             this.showError = true;
              this.errorMsg = err.response.data.fieldErrors[0].defaultMessage

          }
         
        })
      },
      upload(){
        this.showUpload = !this.showUpload;
      },
        
      checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
              console.log(response.data)
                  
					}).catch((err) => {
            if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  
					

					})
			},
     
             

          
    },
     watch:{
        'areaCode': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.profile.mobilePhone = '';
				        this.profile.country = '';
            }
       }
      },
    beforeMount(){
         this.checkSession();
         	this.getMobileCodes();
			    this.changeFlag();
        
        
    },
}
</script>
<style scoped lang="scss">
    .blob img{
        z-index: -999;
        display: flex;
        width: 40em;
        position: absolute;
    
    }
    .btn-upload{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width: auto;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:#007bff !important;
  }
	.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width: 100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:#007bff !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	  	background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color: black !important;
  }
   .btn-second:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second {
    color: black;
    background-color: #007bff;
    border-color: #007bff; width: 100%;
  }
 
	strong{
		color: red;
	}
	.form{
		width: 500px !important;
		justify-content: center !important;
		margin: 0 auto !important;
		padding: 0px !important;

	}

	
	.logo {
	    display:flex;
	    justify-content: center;
	    align-items: center;
	    margin-bottom: 1em;
	    img{
	      width: 10rem;  
	    }
	    
	  } 

    .btn {
    	font-weight: bold;
    }

	.main-wrapper {
		display: flex;
	    justify-content: center;
	    height: 100%;
	    flex-direction: column;
	    align-items: center;
		width: 100vw;
	}

	.wrapper {
		padding: 2em;
    	background: O rgba(255,255,255,0.2);
		backdrop-filter:blur(20px);
		box-shadow:0 0 30px rgba(0,0,0,0.3);
	    display: flex;
	    flex-direction: column;
	    justify-content: space-evenly;
	   
		width: 40em;
		height: auto;
		z-index: 2;
		form {
	      color: #FFF;
		  width: 100%;
	    }
	}
	#lnkSignup {
		display:flex;
		align-items: center;
		justify-content: center;
		
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	 .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #de8413; 
        bottom: 0.5em;
        width: 100%;
    }
    .soc-med{
      border: 1px solid #3D4FBB;
      border-radius: 4px;
      margin: 10px !important;
      padding: 0px !important;
    }
    .socmed-img{
        width: 10px;
    }
	 .flag-list-img {
        width: 30px;
    }

    .mobile-container {
        position: relative;
		
    }

	.mobile-wrapper {
		position:relative;
	}

    .default-flag {
        width: 20px;
		margin-right:0.25em;
    }

    .country-list {
        position: absolute;
        background: rgb(204, 201, 201);
        border-radius: 3px;
        color: #000 !important;
        bottom: 30px;
        padding: 0.1em;
        height: 10em;
        overflow: auto;
        z-index: 99;
        
    }
	 .row {
        margin: 0 !important;
    }
    .col-sm-6 {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .col-sm-6:hover {
        background: darken(#3468c7, 10%);
    }
    .country-name {
        margin-left: 0.5em;
    }

    span {
        font-size: 0.9em;
    }
    .flag-btn {
        width: 50px;
        border-radius: 3px;
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none !important;
        border-radius: 10px;;
    }

	.icon {
		padding:0px;
	}
     h6{
         padding-right: 5px;
    }

    .code {
        background-color:white;
		height: 40px;
        font-size:15px;
		border-radius: 10px;;
        
    }
	.mobileWrapper{
			display: flex !important;
		}


	@media screen and (max-width: 508px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
	   .blob img{
		   display: none;
	   }
	   .form{
		width: 300px !important;
		}
		.d-flex{
			display: block !important;
		}
		.mobileWrapper{
			display: flex;
		}
		

       
    }
     @media (min-width: 500px) and (max-width: 580px) {
		 .form{
		width: 300px !important;
		}
	

     }

    @media (min-width: 600px) and (max-width: 1024px) {
		.blob img{
		   display: none;
	   }
	
       .main-wrapper{
			height: 100% !important;
		}
       
    }
    @media only screen and (min-width: 1280) {
      
    
    }
</style>le>